<template>
    <div class="modal-row2 modal-height-max" style="overflow-x: hidden;">
        <div class="row gutters">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0"
                               id="allotment-tab" data-toggle="tab" href="#allotment" role="tab"
                               aria-controls="allotment" aria-disabled="true">{{ t('ALLOTMENT.GENERAL') }}</a>
                        </li>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 1 }"
                               @click.stop.prevent="validateSecondAbaClick" id="localizations-tab" data-toggle="tab"
                               href="#localizations" role="tab" aria-controls="localizations"
                               aria-disabled="true">{{ t('LOTS.ONUS') }}</a>
                        </li>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 2 }"
                               @click.stop.prevent="validateThreeAbaClick" id="dimensions-tab" data-toggle="tab"
                               href="#dimensions" role="tab" aria-controls="dimensions"
                               aria-disabled="true">{{ t('LOTS.DIMENSIONS') }}</a>
                        </li>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 3 }"
                               @click.stop.prevent="validateLastAbaClick" id="description-tab" data-toggle="tab"
                               href="#dimensions" role="tab" aria-controls="dimensions"
                               aria-disabled="true">{{ t('LOTS.DESCRIPTION') }}
                            </a>
                        </li>
                    </div>
                </ul>
                <form @submit.prevent="create" class="pt-2">
                    <div class="tab-content modal-body" id="myTabContent">
                        <div class="tab-pane fade" :class="{active: activeTab == 0, show: activeTab == 0}"
                             id="allotment" role="tabpanel" aria-labelledby="allotment-tab">
                            <div class="row gutters">
                                <div class="'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'" v-if="hastwoAllotments">
                                    <div class="form-group">
                                        <label for="allotment_id">{{ t('LOTS.ALLOTMENT') }}: </label>
                                        <Select2
                                                :settings="{ width: '100%', placeholder: 'Selecione um Loteamento', disabled: true}"
                                                id="allotment_id" name="allotment_id" v-model="properties.allotment_id"
                                                :options="allotments"
                                                @select="selectGeneric(properties.allotment_id, 'Allotment')"/>
                                        <div class="validation" id="invalidAllotment" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" v-if="allotmentSelected?.id_configuracao_bd">
                                    <div class="form-group">
                                        <label for="owner_id"> {{ t('PROPERTIES.OWNER_NAME') }}: </label>
                                        <Select2 v-if="properties.owner_name"
                                                 :settings="settingsOwner"
                                                 :options="[{id:properties.owner_id, text: properties.owner_name}]"
                                                 @select="setOwnerName"
                                                 placeholder="Selecione um Proprietário"
                                                 id="owner_id" name="owner_id"
                                                 :disabled="disabledField()"
                                                 v-model="properties.owner_id"/>
                                        <Select2 v-else
                                                 :settings="settingsOwner"
                                                 placeholder="Selecione um Proprietário"
                                                 id="owner_id" name="owner_id"
                                                 :disabled="disabledField()"
                                                 v-model="properties.owner_id"/>
                                        <div class="validation" id="invalidOwner" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" v-if="allotmentSelected?.id_configuracao_bd">

                                    <div class="form-group">
                                        <label for="parceria_id"> {{ t('PROPERTIES.PARCERIA_NAME') }}: </label>
                                        <Select2 v-if="properties.parceria_name"
                                                 :settings="settingsParceria"
                                                 :options="[{id:properties.parceria_id, text: properties.parceria_name}]"
                                                 @select="setParceriaName"
                                                 :disabled="disabledField()"
                                                 placeholder="Selecione uma Parceria"
                                                 id="parceria_id" name="parceria_id"
                                                 v-model="properties.parceria_id"/>
                                        <Select2 v-else
                                                 :disabled="disabledField()"
                                                 :settings="settingsParceria"
                                                 placeholder="Selecione uma Parceria"
                                                 id="parceria_id" name="parceria_id"
                                                 v-model="properties.parceria_id"/>
                                        <div class="validation" id="invalidParceria" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="front_size">{{ t('LOTS.FRONT_CONFRONTATION') }}: </label>
                                        <input type="text" id="front_confrontation" v-model="lots.front_confrontation"
                                               @keyup="maxCharacters(lots.front_confrontation, 255, 'front_confrontation')"
                                               name="front_confrontation" class="form-control"  :disabled="disabledField()">
                                        <div class="validation" id="invalid_front_confrontation" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="state" class="required">{{ t('LOTS.BLOCK') }}: </label>
                                        <input type="text" class="form-control" id="block" name="block"
                                               v-model="properties.block" :disabled="(properties?.id && properties?.properties_status?.length &&
                                     properties?.properties_status[0]?.text?.toUpperCase() === 'VENDIDO') || disabledField()"
                                               required="required" @keyup="inputGeneric(properties.block, 'block')"
                                               maxlength="10">
                                        <div class="validation" id="invalidblock" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="name" class="required">{{ t('LOTS.LOT') }}: </label>
                                        <input type="text" id="name" v-model="properties.name" name="name"
                                               required="required" @keyup="inputName(properties.name)"
                                               :disabled="(properties?.id && properties?.properties_status?.length &&
                                     properties?.properties_status[0]?.text?.toUpperCase() === 'VENDIDO') || disabledField()"
                                               class="form-control" maxlength="10">
                                        <div class="validation" v-if="isNameInvalid">{{ t(msgError) }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div data-cy="situation_lot" class="form-group">
                                        <label for="status" class="required">{{ t('LOTS.SITUATION') }}: </label>
                                        <Select2
                                                :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'uma', name: t('LOTS.SITUATION')}) }"
                                                id="status" name="status" v-model="properties.properties_status_id"
                                                :options="situations"
                                                :disabled="(properties?.id && properties?.properties_status?.length &&
                                     properties?.properties_status[0]?.text?.toUpperCase() === 'VENDIDO')"
                                                @select="selectGeneric(properties.properties_status_id, 'Status')"/>
                                        <div class="validation" id="invalidStatus" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="properties_type_id" class="required">{{
                                                t('NAV.PROPERTY_TYPES')
                                            }}: </label>
                                        <Select2
                                                :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('NAV.PROPERTY_TYPES')}) }"
                                                id="properties_type_id" name="properties_type_id"
                                                :disabled="disabledField()"
                                                v-model="properties.properties_type_id" :options="propertyTypes"
                                                @select="selectGeneric($event, 'Properties')"/>
                                        <div class="validation" id="invalidProperties" style="display: none">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="price" class="required">{{ t('PROPERTIES.PRICE') }}: </label>
                                        <money3 id="price" v-model="properties.price"  :disabled="disabledField()"
                                                name="price" class="form-control "
                                                v-bind="configPrice"></money3>
                                        <div class="validation" id="invalidPriceLots" style="display: none">
                                            Valor minimo R$ 1,00
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="brokerageValue">{{ t('PROPERTIES.BROKERAGE_VALUE') }}: </label>
                                        <money3 id="brokerageValue" v-model="properties.brokerage_value"
                                                name="price" class="form-control "  :disabled="disabledField()"
                                                v-bind="config"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="price_coust">{{ t('LOTS.PRICE_COUST') }}: </label>
                                        <money3 v-bind="config" v-model="properties.cost_value"
                                                class="form-control" id="price_coust"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="total_area" class="required">{{ t('LOTS.TOTAL_AREA') }}: </label>
                                        <money3 v-bind="configAreaTotal" v-model="properties.total_area"
                                                class="form-control" id="total_area"  :disabled="disabledField()"></money3>
                                        <div class="validation" id="invalidTotalArea" style="display: none">
                                            Valor mínimo: 1
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="metragem_area">{{ t('LOTS.METRAGEM_TOTAL') }}: </label>
                                        <money3 v-bind="configAreaTotal" v-model="properties.metragem_total"
                                                class="form-control" id="metragem_area"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="ideal_fraction">{{ t('LOTS.IDEAL_FRACTION') }}: </label>
                                        <money3 v-bind="configFraction" v-model="properties.ideal_fraction"
                                                class="form-control" id="ideal_fraction"  :disabled="disabledField()"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="numero_registro">{{ t('PROPERTIES.NUMERO_REGISTRO') }}: </label>
                                        <input type="text" id="numero_registro"
                                               maxlength="60"
                                               v-model="properties.numero_registro"
                                               name="numero_registro" class="form-control" step=".01"  :disabled="disabledField()">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="inscricao_cadastral_iptu">{{
                                                t('PROPERTIES.INSCRICAO_CADASTRAL_IPTU')
                                            }}: </label>
                                        <input type="text" id="inscricao_cadastral_iptu"
                                               maxlength="25"
                                               v-model="properties.inscricao_cadastral_iptu"
                                               name="inscricao_cadastral_iptu" class="form-control" step=".01"  :disabled="disabledField()">
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                    <div class="form-group">
                                        <label>{{ t('ALLOTMENT.LATITUDE') }}: </label>
                                        <input type="number" id="latitude" v-model="properties.latitude" name="latitude"  :disabled="disabledField()"
                                               class="form-control" step=".000001">
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                    <div class="form-group">
                                        <label for="longitude">{{ t('ALLOTMENT.LONGITUDE') }}: </label>
                                        <input type="number" id="longitude" v-model="properties.longitude"  :disabled="disabledField()"
                                               name="longitude" class="form-control" step=".000001">
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 d-flex ">
                                    <div class="custom-control custom-switch align-self-center">
                                        <input type="checkbox" class="custom-control-input"
                                               id="hasbuildg"  :disabled="disabledField()"
                                               v-model="properties.has_building">
                                        <label class="custom-control-label" for="hasbuildg">
                                            {{ t('PROPERTIES.HAS_BUILDINGS') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="observation">{{ t('LOTS.IMPROVEMENTS') }}:</label>
                                        <textarea class="form-control" id="improvements" rows="4"
                                                  placeholder="Benfeitorias"  :disabled="disabledField()"
                                                  maxlength="255"
                                                  v-model="properties.improvements"></textarea>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="observation">{{ t('LOTS.OBSERVATION') }}:</label>
                                        <textarea class="form-control"
                                                  id="observation"  :disabled="disabledField()"
                                                  rows="4"
                                                  maxlength="255"
                                                  placeholder="Observações"
                                                  v-model="properties.observation"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }"
                             id="localizations" role="tabpanel" aria-labelledby="localizations-tab">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="onus_description">{{ t('LOTS.ONUS_DESCRIPTION') }}: </label>
                                        <input type="text" v-model="properties.onus_description" class="form-control"  :disabled="disabledField()"
                                               id="onus_description" placeholder="Titulo">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="onus">{{ t('LOTS.ONUS') }}:</label>
                                        <textarea class="form-control" id="onus" rows="4"  :disabled="disabledField()"
                                                  placeholder="Ônus" maxlength="255"
                                                  v-model="properties.onus">
                              </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 2, show: activeTab == 2 }"
                             id="dimensions" role="tabpanel" aria-labelledby="dimensions-tab">
                            <div>
                                <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                    {{ this.t('GENERAL.MEASUREMENTS') }}
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="front_size">{{ t('LOTS.MEASURE_FRONT') }}: </label>
                                        <input type="text" id="front_size" v-model="lots.front_size" name="front_size"
                                               maxlength="60"
                                               class="form-control"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.front_size, 255, 'front_size')">
                                        <div class="validation" id="invalid_front_size" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="back_size">{{ t('LOTS.MEASURE_BACK') }}: </label>
                                        <input type="text" id="back_size" v-model="lots.back_size" name="back_size"
                                               maxlength="60"
                                               class="form-control"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.back_size, 255, 'back_size')">
                                        <div class="validation" id="invalid_back_size" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="chamfer_size">{{ t('LOTS.MEASURE_CHANFER') }}: </label>
                                        <input type="text" id="chamfer_size" v-model="lots.chamfer_size"
                                               maxlength="60"
                                               name="chamfer_size" class="form-control"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.chamfer_size, 255, 'chamfer_size')">
                                        <div class="validation" id="invalid_chamfer_size" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="right_size">{{ t('LOTS.MEASURE_RIGHT') }}: </label>
                                        <input type="text" id="right_size" v-model="lots.right_size" name="right_size"
                                               maxlength="60"
                                               class="form-control"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.right_size, 255, 'right_size')">
                                        <div class="validation" id="invalid_right_size" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="left_size">{{ t('LOTS.MEASURE_LEFT') }}: </label>
                                        <input type="text" id="left_size" v-model="lots.left_size" name="left_size"
                                               class="form-control"  :disabled="disabledField()" maxlength="60"
                                               @keyup="maxCharacters(lots.left_size, 255, 'left_size')">
                                        <div class="validation" id="invalid_left_size" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="dropdown-divider"></div>
                                <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                    {{ this.t('GENERAL.CONFRONTATIONS') }}
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="back_size">{{ t('LOTS.BACKGROUND_CONFRONTATION') }}: </label>
                                        <input type="text" id="background_confrontation"
                                               maxlength="60"
                                               v-model="lots.background_confrontation"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.background_confrontation, 255, 'background_confrontation')"
                                               name="background_confrontation" class="form-control">
                                        <div class="validation" id="invalid_background_confrontation"
                                             style="display: none">{{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="chamfer_size">{{ t('LOTS.RIGHT_CONFRONTATION') }}: </label>
                                        <input type="text" id="right_confrontation" v-model="lots.right_confrontation"
                                               maxlength="60"
                                               @keyup="maxCharacters(lots.right_confrontation, 255, 'right_confrontation')"  :disabled="disabledField()"
                                               name="right_confrontation" class="form-control">
                                        <div class="validation" id="invalid_right_confrontation" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="front_size">{{ t('LOTS.LEFT_CONFRONTATION') }}: </label>
                                        <input type="text" id="left_confrontation" v-model="lots.left_confrontation"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.left_confrontation, 255, 'left_confrontation')"
                                               name="left_confrontation" class="form-control" maxlength="60">
                                        <div class="validation" id="invalid_left_confrontation" style="display: none">
                                            {{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="back_size">{{ t('LOTS.CHAMFER_CONFRONTATION') }}: </label>
                                        <input type="text" id="chamfer_confrontation"
                                               maxlength="60"
                                               v-model="lots.chamfer_confrontation"  :disabled="disabledField()"
                                               @keyup="maxCharacters(lots.chamfer_confrontation, 255, 'chamfer_confrontation')"
                                               name="chamfer_confrontation" class="form-control">
                                        <div class="validation" id="invalid_chamfer_confrontation"
                                             style="display: none">{{ t('GENERAL.MSG.MAXIM_LIMIT_CHARACTER') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 3, show: activeTab == 3 }"
                             id="description" role="tabpanel" aria-labelledby="description-tab">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="description">{{ t('LOTS.DESCRIPTION') }}: </label>
                                        <editor :init="configTinyMce" v-model="properties.description"
                                                @update:modelValue="descriptionChange($event)"  :disabled="disabledField()"
                                                tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js">
                                        </editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="justify-content: space-between">
                        <div>
                            <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }}
                                *</h5>
                        </div>
                        <div>
                            <button data-cy="lot_creat_previous" type="button" v-if="activeTab != 0"
                                    class="btn btn-secondary mr-2" @click="activeTab--">{{ t('ALLOTMENT.PREVIOUS') }}
                            </button>
                            <button data-cy="lot_creat_next" type="button" v-if="activeTab != 3"
                                    class="btn btn-secondary mr-2" @click="next">{{ t('ALLOTMENT.NEXT') }}
                            </button>
                            <button data-cy="lot_creat_save" type="submit" v-if="activeTab == 3 || isEdit"
                                    class="btn btn-primary">{{ t('ACTIONS.SAVE') }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import Lots from "@/services/Lots";
import {Money3Component} from 'v-money3';
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import PropertyTypes from "@/services/propertyTypes";
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'createLot',
    mixins: [Validate],
    props: ['allotments', 'propertiesIndex', 'lotsIndex', 'hastwoAllotments', 'isEdit', 'allotmentSelected','propertiesStatusIndex'],
    components: {
        Select2,
        Editor,
        money3: Money3Component,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            form: {},
            lots: {},
            invalid: [],
            activeTab: 0,
            situations: [],
            properties: {},
            propertyTypes: [],
            isNameInvalid: false,
            configTinyMce: {
                selector: 'textarea',
                license_key: 'gpl',
                contextmenu: false,
                plugins: 'print preview searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern emoticons',
                menubar: 'edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                images_upload_url: '',
                setup: function(editor) {
                    editor.on('Paste', function(e) {
                        // Impede a colagem de imagens
                        var items = e.clipboardData.items;
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].type.indexOf('image/') !== -1) {
                                e.preventDefault(); // Impede a colagem de imagens
                                alert('Colar imagens não é permitido.'); // Mensagem opcional
                                return;
                            }
                        }
                    });
                    editor.on('drop', function(e) {
                        // Previne a ação padrão de arrastar e soltar
                        e.preventDefault();
                        e.stopPropagation();
                        // Se desejar, pode mostrar uma mensagem ao usuário
                        alert('Arrastar e soltar de imagens não é permitido.');
                    });
                },
                file_picker_callback: function() {
                    // Desabilitar o seletor de arquivos
                    return false;
                },
                images_upload_handler: function (blobInfo, success, failure) {
                    // Desabilitar completamente o upload de imagens
                    failure('Upload de imagem desabilitado');
                },
                image_advtab: false,  // Desabilitar a aba avançada de imagens
                image_title: false,   // Desabilitar a opção de título de imagem
                automatic_uploads: false, // Desabilita uploads automáticos
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            settingsOwner: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/proprietarios`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                        'allotment-id': this.allotmentSelected.id,
                    },
                    data: params => {
                        return {
                            name: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                    cache: true
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
            settingsParceria: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 0,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/parceria-externo`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                        'allotment-id': this.allotmentSelected.id,
                    },
                    data: params => {
                        return {
                            name: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 'nenhum', text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                    cache: true
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
            configAreaTotal: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 4,
                prefix: '',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            },
            configPrice: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            },
            config: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            },
            configFraction: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 9,
                disabled: false,
                thousands: '',
                max: 100,
                allowBlank: false,
                disableNegative: true,
            },
        }
    },
    mounted() {
        this.setAllotment();
        this.startModalInfs();
        this.indexSituation();
        this.indexPropertyTypes();
    },

    watch: {
        propertiesIndex: function () {
            this.updateModalInfs();
        }
    },

    methods: {
        disabledField() {
            let status = this.lots?.properties?.properties_status?.find((i) => i);
            if (status?.text && ((status.text.toLowerCase()) === 'reserva confirmada')) {
                this.configPrice.disabled = true;
                this.configAreaTotal.disabled = true;
                this.config.disabled = true;
                this.configFraction.disabled = true;
                return true;
            }
            return false;
        },
        create() {
            let price = this.properties.price.toString();
            price = parseFloat(price.replace('R$ ', ''));
            if (price < 1) {
                document.getElementById('price').classList.add('is-invalid');
                document.getElementById('invalidPriceLots').style.display = 'block';
                return;
            } else {
                document.getElementById('price').classList.remove('is-invalid');
                document.getElementById('invalidPriceLots').style.display = 'none';
            }
            this.startForm();
            if (this.checkForm()) {
                (!this.form.properties.status) ? delete this.form.properties.status : delete this.form.properties.status;
                this.$store.commit('changeLoading', true);
                let form = {...this.form};
                if (form.properties.brokerage_value) {
                    form.properties.brokerage_value = parseFloat(form.properties.brokerage_value.replace('R$ ', '').replace('.', '').replace(',', '.'));
                }
                if (form.properties.metragem_total) {
                    form.properties.metragem_total = parseFloat(form.properties.metragem_total.replace('.', '').replace(',', '.'));
                }
                if (form.properties?.total_area && typeof form.properties.total_area === 'string' && form.properties.total_area.includes(',')) {
                    form.properties.total_area = parseFloat(form.properties.total_area.replace('.', '').replace(',', '.'));
                }
                if (form.properties.ideal_fraction) {
                    form.properties.ideal_fraction = parseFloat(form.properties.ideal_fraction.replace('%', ''));
                }
                if (form.properties.parceria_id === 'nenhum') {
                    form.properties.parceria_id = null;
                }
                if (this.form.id) {
                    Lots.update(this.form.id, form).then(resp => {
                        document.getElementById('closeX').click();
                        this.$emit('edited', resp.data)
                        this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));
                        this.$store.commit('changeLoading', false);
                    }).catch(error => {
                        this.errorMsg(error);
                        this.$store.commit('changeLoading', false);
                    });
                } else {
                    Lots.create(form).then(resp => {
                        document.getElementById('closeX').click();
                        this.$emit('created', resp.data)
                        this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                        this.$store.commit('changeLoading', false);
                    }).catch(error => {
                        this.errorMsg(error);
                        this.$store.commit('changeLoading', false);
                    });
                }
            }
        },
        setOwnerName({text}) {
            this.properties.owner_name = text;
        },
        setParceriaName({text}) {
            this.properties.parceria_name = text;
        },
        startModalInfs() {
            this.lots = this.lotsIndex;
            this.properties = this.propertiesIndex;
            this.properties.price = this.properties.price * 1;
            this.properties.description = this.properties.description ? this.properties.description : '';

            document.getElementById('customModalTwo').style.position = this.isEdit ? 'absolute' : 'fixed';
        },

        updateModalInfs() {
            this.activeTab = 0;
            this.indexSituation();
            this.indexPropertyTypes();
            this.lots = this.lotsIndex;
            this.isNameInvalid = false;
            this.properties = this.propertiesIndex;
            if (this.properties.brokerage_value) this.properties.brokerage_value = parseFloat(this.properties.brokerage_value).toFixed(2);
            if (this.properties.metragem_total) this.properties.metragem_total = parseFloat(this.properties.metragem_total).toFixed(4);
            if (this.properties.total_area) this.properties.total_area = parseFloat(this.properties.total_area).toFixed(4);
            if (this.properties.ideal_fraction) this.properties.ideal_fraction = parseFloat(this.properties.ideal_fraction).toFixed(9);
            this.properties.price = this.properties.price * 1;
            this.properties.cost_value = this.properties.cost_value * 1;

            if (this.properties?.id && this.properties?.properties_status?.length &&
                    this.properties?.properties_status[0]?.text?.toUpperCase() === 'VENDIDO') {
                this.configPrice.disabled = true;
            } else {
                this.configPrice.disabled = false;
            }
            document.getElementById('name').classList.remove('is-valid');
            document.getElementById('block').classList.remove('is-valid');
            document.getElementById('name').classList.remove('is-invalid');
            document.getElementById('invalidblock').style.display = 'none';
            document.getElementById('block').classList.remove('is-invalid');
            document.getElementById('invalidStatus').style.display = 'none';
            document.getElementById('invalidProperties').style.display = 'none';
            this.properties.description = this.properties.description ? this.properties.description : '';

            document.getElementById('customModalTwo').style.position = this.isEdit ? 'absolute' : 'fixed';
        },

        next() {
            if (this.checkForm()) {
                this.activeTab++;
            }
        },

        startForm() {
            var priceFinal;

            this.lots.properties = this.properties;
            this.form = this.lots;

            if (this.form.properties.price && typeof this.form.properties.price === "string") {
                priceFinal = this.form.properties.price.replaceAll("R$ ", "");
                if (priceFinal.indexOf(".")) {
                    priceFinal = priceFinal.replaceAll(".", "");
                }
                priceFinal = priceFinal.replaceAll(",", ".");

            } else {
                priceFinal = this.form.properties.price;
            }

            this.form.properties.price = priceFinal;

            if (this.form.properties.cost_value && typeof this.form.properties.cost_value === "string") {
                priceFinal = this.form.properties.cost_value.replaceAll("R$ ", "");
                if (priceFinal.indexOf(".")) {
                    priceFinal = priceFinal.replaceAll(".", "");
                }
                priceFinal = priceFinal.replaceAll(",", ".");

            } else {
                priceFinal = null;
            }

            this.form.properties.cost_value = priceFinal;
        },

        checkForm() {
            this.invalid = [];

            this.nameValidate(this.properties.name);
            this.validateGeneric(this.properties.block, 'block');
            this.validateGeneric(this.properties.properties_status_id, 'Status', false);
            this.validateGeneric(this.properties.properties_type_id, 'Properties', false);
            this.maxCharacters(this.lots.front_confrontation, 255, 'front_confrontation')
            this.maxCharacters(this.lots.background_confrontation, 255, 'background_confrontation')
            this.maxCharacters(this.lots.right_confrontation, 255, 'right_confrontation')
            this.maxCharacters(this.lots.left_confrontation, 255, 'left_confrontation')
            this.maxCharacters(this.lots.chamfer_confrontation, 255, 'chamfer_confrontation')

            let price = this.properties.price.toString();
            price = parseFloat(price.replace('R$ ', ''));
            if (price < 1) {
                document.getElementById('price').classList.add('is-invalid');
                document.getElementById('invalidPriceLots').style.display = 'block';
                this.invalid.push({})
            } else {
                document.getElementById('price').classList.remove('is-invalid');
                document.getElementById('invalidPriceLots').style.display = 'none';
            }

            if (!this.properties.total_area || this.properties.total_area < 1) {
                document.getElementById('total_area').classList.add('is-invalid');
                document.getElementById('invalidTotalArea').style.display = 'block';
                this.invalid.push({})
            } else {
                document.getElementById('total_area').classList.remove('is-invalid');
                document.getElementById('invalidTotalArea').style.display = 'none';
            }

            if (this.hastwoAllotments) {
                this.validateGeneric(this.properties.allotment_id, 'Allotment', false)
            }
            if (this.properties.owner_id == 0) {
                this.properties.owner_id = null;
            }

            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        setAllotment() {
            if (!this.hastwoAllotments) {
                this.properties.allotment_id = this.allotments
            }
        },

        validateSecondAbaClick() {
            if (this.checkForm()) {
                this.activeTab = 1;
            }
        },

        validateThreeAbaClick() {
            if (this.checkForm()) {
                this.activeTab = 2;
            }
        },
        validateLastAbaClick() {
            if (this.checkForm()) {
                this.activeTab = 3;
            }
        },
        indexPropertyTypes() {
            if (this.isEdit) {
                this.propertyTypes = this.propertiesIndex.properties_types;
            } else {
                PropertyTypes.list().then(resp => {
                    this.propertyTypes = resp.data;
                })
            }
        },

        indexSituation() {
            this.situations = [];
            if (this.isEdit && !this.disabledField()) {
                let status = this.propertiesIndex?.properties_status?.find((i) => i);
                if (status && ((status.text.toLowerCase()) === 'reserva confirmada')){
                    this.situations = this.propertiesStatusIndex.filter(i => i.can_select);
                }else {
                    this.situations = this.propertiesIndex.properties_status;
                }
            } else {
                this.situations = this.propertiesStatusIndex.filter(i => i.can_select);
            }
        },

        descriptionChange(description) {
            this.properties.description = description;
        },
    }
}
</script>
